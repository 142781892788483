import React from 'react';

import type { Company as _Company } from 'common/api/endpoints/companies';

export type Company = _Company;

/**
 * The company can be null if the CompanyContext does not have a provider, or the subdomain is not registered
 */
export const CompanyContext = React.createContext<Company>(null as unknown as Company);

type Props = {
  children: React.ReactNode;
  company: Company;
};

const CompanyContainer = ({ company, children }: Props) => {
  return <CompanyContext.Provider value={company}>{children}</CompanyContext.Provider>;
};

export default CompanyContainer;
